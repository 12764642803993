"use client";

import { useEffect } from "react";
import { Frame } from "@/app/components/Frame";
import { Prose } from "@/app/components/Prose";
import { UnderlineTextButton } from "@/app/components/Button";

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
  }, [error]);

  return (
    <Frame>
      <Prose>
        <h2>Ein Fehler ist aufgetreten</h2>
        <p>
          <UnderlineTextButton
            onClick={
              // Attempt to recover by trying to re-render the segment
              () => location.reload()
            }
          >
            Erneut versuchen
          </UnderlineTextButton>
        </p>
      </Prose>
    </Frame>
  );
}
