import { AnchorHTMLAttributes, ButtonHTMLAttributes, FC } from "react";
import classNames from "classnames";
import styles from "./Button.module.css";
import Link, { LinkProps } from "next/link";

export const MainButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  className,
  ...props
}) => <button {...props} className={classNames(styles.main, className)} />;

export const MainLink: FC<
  LinkProps & AnchorHTMLAttributes<HTMLAnchorElement>
> = ({ className, ...props }) => (
  <Link {...props} className={classNames(styles.main, className)} />
);

export const FooterMainButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  className,
  ...props
}) => (
  <button
    {...props}
    className={classNames(styles.main, styles.footer, className)}
  />
);

export const FooterMainLink: FC<
  LinkProps & AnchorHTMLAttributes<HTMLAnchorElement>
> = ({ className, ...props }) => (
  <Link
    {...props}
    className={classNames(styles.main, styles.footer, className)}
  />
);

export const UnderlineTextButton: FC<
  ButtonHTMLAttributes<HTMLButtonElement>
> = ({ className, ...props }) => (
  <button {...props} className={classNames(styles.underline, className)} />
);

export const UnderlineTextLink: FC<
  LinkProps & AnchorHTMLAttributes<HTMLAnchorElement>
> = ({ className, ...props }) => (
  <Link {...props} className={classNames(styles.underline, className)} />
);
